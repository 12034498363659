import { getBridge } from '../../components/Native.tsx';
import { Navigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { ParkingaboLogoHeader } from '../../components/layout/ParkingaboLogoHeader.tsx';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import { Language } from 'dg-web-shared/lib/Localized.ts';
import AppstoreDe from '../../../assets/appstore-de.svg';
import AppstoreFr from '../../../assets/appstore-fr.svg';
import AppstoreIt from '../../../assets/appstore-it.svg';
import AppstoreEn from '../../../assets/appstore-en.svg';
import GooglePlayBadgeDe from '../../../assets/google-play-badge-de.svg';
import GooglePlayBadgeFr from '../../../assets/google-play-badge-fr.svg';
import GooglePlayBadgeIt from '../../../assets/google-play-badge-it.svg';
import GooglePlayBadgeEn from '../../../assets/google-play-badge-en.svg';
import { ParkingaboFooter } from '../../components/layout/ParkingaboFooter.tsx';
import { isAndroid, isIos } from 'dg-web-shared/common/utils/BrowserOrigin.ts';

export function LandingPage() {
    const bridge = getBridge();
    const android = isAndroid();
    const iOs = isIos();

    if (bridge) {
        return <Navigate to={'/login'} />;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
            }}
        >
            <ParkingaboLogoHeader />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        width: '75%',
                        textAlign: 'center',
                        paddingBottom: '40px',
                    }}
                >
                    <Localized
                        de="Um loszulegen, laden Sie die Parkingabo App aus dem Store herunter."
                        fr="Pour commencer, téléchargez l’app Parkingabo sur le Store."
                        it="Per iniziare, scaricare l'app Parkingabo dallo Store."
                        en="To get started, download the Parkingabo app from the Store."
                    />
                </Box>
                {!iOs && (
                    <Link
                        sx={{
                            width: '50%',
                        }}
                        href={
                            'https://play.google.com/store/apps/details?id=ch.parkingabo'
                        }
                    >
                        <GooglePlayBadge />
                    </Link>
                )}
                {!android && (
                    <Link
                        sx={{
                            width: '50%',
                        }}
                        href={
                            'https://apps.apple.com/ch/app/parkingabo/id6739490360'
                        }
                    >
                        <Appstore />
                    </Link>
                )}
            </Box>
            <Box
                sx={{
                    textDecoration: 'underline',
                    paddingBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Link href={'/login'}>
                    <Localized
                        de="Weiter mit der Web-Version"
                        fr="Continuer avec la version web"
                        it="Continua con la versione web"
                        en="Continue with the web version"
                    />
                </Link>
            </Box>
            <ParkingaboFooter />
        </Box>
    );
}

function Appstore() {
    const { language } = useLanguage();
    switch (language) {
        case Language.DE:
            return <img src={AppstoreDe} />;
        case Language.FR:
            return <img src={AppstoreFr} />;
        case Language.IT:
            return <img src={AppstoreIt} />;
        case Language.EN:
            return <img src={AppstoreEn} />;
    }
}

function GooglePlayBadge() {
    const { language } = useLanguage();
    switch (language) {
        case Language.DE:
            return <img src={GooglePlayBadgeDe} />;
        case Language.FR:
            return <img src={GooglePlayBadgeFr} />;
        case Language.IT:
            return <img src={GooglePlayBadgeIt} />;
        case Language.EN:
            return <img src={GooglePlayBadgeEn} />;
    }
}
