import { Box } from '@mui/material';

import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboFooter } from '../../../components/layout/ParkingaboFooter';
import { ParkingaboMenuListItem } from '../../../components/ParkingaboMenuListItem';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { Logout, Report } from '@mui/icons-material';
import { AliasInvalidReason } from '../../../shared/ParkingaboModels';
import styled from '@emotion/styled';
import { ReactElement } from 'react';
import { useTenant } from '../../../components/TenantProvider.tsx';

export function SettingsRoute({ user, logout }: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const {
        tenant: { tenantId },
    } = useTenant();
    return (
        <ParkingaboLayoutWithHeader
            noGutter
            backTo={generateAuthedParkingaboPath('')}
            title={
                <Localized
                    de="Einstellungen"
                    fr="Paramètres"
                    it="Impostazioni"
                    en="Settings"
                />
            }
        >
            <Box>
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user')}
                    text={
                        <Localized
                            de="Benutzer"
                            fr="Utilisateur"
                            it="Utente"
                            en="User"
                        />
                    }
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/payment')}
                    text={
                        <Localized
                            de="Zahlungsmittel"
                            fr="Moyen de paiement"
                            it="Mezzo di pagamento"
                            en="Payment mean"
                        />
                    }
                    icons={[
                        <AliasInvalidDisclaimer
                            aliasInvalidReason={user.aliasInvalidReason}
                            key={0}
                        />,
                    ]}
                />
                <ParkingaboMenuListItem
                    to=""
                    onClick={logout}
                    text={
                        <Localized
                            de="Abmelden"
                            fr="Déconnecter"
                            it="Esci"
                            en="Logout"
                        />
                    }
                    icons={[<Logout key={0} />]}
                    hideChevron
                />
            </Box>
            <ParkingaboFooter tenantId={tenantId} />
        </ParkingaboLayoutWithHeader>
    );
}

function AliasInvalidDisclaimer({
    aliasInvalidReason,
}: {
    aliasInvalidReason: AliasInvalidReason | null;
}): JSX.Element | null {
    switch (aliasInvalidReason) {
        case AliasInvalidReason.EXPIRED:
            return (
                <RenderInvalidDisclaimer
                    invalidDisclaimer={
                        <Localized
                            de="ABGELAUFEN"
                            fr="ÉCHU"
                            it="SCADUTO"
                            en="EXPIRED"
                        />
                    }
                />
            );
        case AliasInvalidReason.LAST_DIRECT_FAILED:
            return (
                <RenderInvalidDisclaimer
                    invalidDisclaimer={
                        <Localized
                            de="ABGELEHNT"
                            fr="REJETÉ"
                            it="RIFIUTATO"
                            en="REJECTED"
                        />
                    }
                />
            );
        case AliasInvalidReason.NOT_FOUND:
        case AliasInvalidReason.ONBOARDING_REQUIRED:
            return (
                <RenderInvalidDisclaimer
                    invalidDisclaimer={
                        <Localized
                            de="FEHLT"
                            fr="MANQUANT"
                            it="MANCANTE"
                            en="MISSING"
                        />
                    }
                />
            );
        case null:
            return null;
    }
}

function RenderInvalidDisclaimer({
    invalidDisclaimer,
}: {
    invalidDisclaimer: ReactElement;
}) {
    return (
        <>
            <InvalidAlias>{invalidDisclaimer}</InvalidAlias>
            <Report sx={{ color: 'rgb(241, 11, 11)' }} />
        </>
    );
}

const InvalidAlias = styled(Box)`
    color: rgb(241, 11, 11);
`;
