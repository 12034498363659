import {
    ServerRequestState,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { AliasInvalidReason } from '../../../shared/ParkingaboModels';
import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthedRouteCompProps } from '../../RouteUtils.tsx';
import { useTenant } from '../../../components/TenantProvider.tsx';
import { AppMode } from 'dg-web-shared/model/TenantEnums.ts';

export interface StepConfig {
    steps: Array<{
        type: OnboardingStepType;
        completed?: boolean;
    }>;
}

export enum OnboardingStepType {
    USER_DATA = 'USER_DATA',
    VEHICLE = 'VEHICLE',
    PAYMENT = 'PAYMENT',
}

const emptyStepConfigSignupByDomain: StepConfig = {
    steps: [
        {
            type: OnboardingStepType.USER_DATA,
        },
        {
            type: OnboardingStepType.VEHICLE,
        },
        {
            type: OnboardingStepType.PAYMENT,
        },
    ],
};

const emptyStepConfigNoSignup: StepConfig = {
    steps: [
        {
            type: OnboardingStepType.PAYMENT,
        },
    ],
};

const OnboardingContext = createContext<{
    config: StepConfig;
    setConfig: Dispatch<SetStateAction<StepConfig>>;
}>({
    config: emptyStepConfigSignupByDomain,
    setConfig: () => {},
});

export function OnboardingProvider({ user, vehicles }: AuthedRouteCompProps) {
    const { tenant } = useTenant();

    const [config, setConfig] = useState(
        tenant.appMode === AppMode.SIGNUP_BY_EMAIL_DOMAIN
            ? emptyStepConfigSignupByDomain
            : emptyStepConfigNoSignup,
    );

    useEffect(() => {
        if (user.firstName && user.lastName) {
            const index = config.steps.findIndex(
                s => s.type === OnboardingStepType.USER_DATA,
            );
            if (index > -1 && !config.steps[index].completed) {
                setOnboardingStepCompletionStatus(setConfig, index, true);
            }
        }

        if (vehicles.data.length > 0) {
            const index = config.steps.findIndex(
                s => s.type === OnboardingStepType.VEHICLE,
            );
            if (index > -1 && !config.steps[index].completed) {
                setOnboardingStepCompletionStatus(setConfig, index, true);
            }
        }

        const paymentIndex = config.steps.findIndex(
            s => s.type === OnboardingStepType.PAYMENT,
        );
        if (paymentIndex > -1) {
            setOnboardingStepCompletionStatus(
                setConfig,
                paymentIndex,
                user.aliasInvalidReason !==
                    AliasInvalidReason.ONBOARDING_REQUIRED,
            );
        }
    }, [vehicles, user]);

    return (
        <OnboardingContext.Provider
            value={{
                config,
                setConfig,
            }}
        >
            <Outlet />
        </OnboardingContext.Provider>
    );
}

export function useOnboarding() {
    return useContext(OnboardingContext);
}

export function useSetOnboardingStepAsCompleteOnSuccess<DT, ET>(
    state: ServerRequestState<DT, ET>,
    currentStep: number,
) {
    const { setConfig } = useOnboarding();

    useServerSuccessEffect(state, () => {
        setOnboardingStepCompletionStatus(setConfig, currentStep, true);
    });
}

export function useNavigateOnStepCompletion(stepToCheck: number, url: string) {
    const { config } = useOnboarding();
    const navigate = useNavigate();
    useEffect(() => {
        if (config.steps[stepToCheck].completed) {
            navigate(url);
        }
    }, [config.steps[stepToCheck].completed]);
}

function setOnboardingStepCompletionStatus(
    setOnboardingConfig: Dispatch<SetStateAction<StepConfig>>,
    currentStep: number,
    completionStatus: boolean,
) {
    setOnboardingConfig(c => ({
        ...c,
        steps: [
            ...c.steps.slice(0, currentStep),
            {
                ...c.steps[currentStep],
                completed: completionStatus,
            },
            ...c.steps.slice(currentStep + 1),
        ],
    }));
}
