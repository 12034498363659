import { Box, Grid, Typography, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';

import { licensePlateOptions } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/CommonOptions';
import {
    ReactHookFormDropdownSelect,
    ReactHookFormSelectOption,
} from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    ParkingaboUserBadgeType,
    VehicleLicensePlateType,
} from 'dg-web-shared/common/models/Vehicle';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { CancelSave } from '../layout/CancelSave';
import { useNavigate } from 'react-router-dom';
import { ParkingaboButton } from '../layout/ParkingaboButton';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage';
import { FeedbackPopup } from '../FeedbackPopup';
import { useEffect, useState } from 'react';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { useLogin } from '../../routes/AppRoutes';
import { GenericFormSubmitError } from '../../shared/GenericFormSubmitError.tsx';
import { useTenant } from '../TenantProvider.tsx';
import {
    badgeTypeOptions,
    isLicensePlateMandatory,
} from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';
import {
    parkingaboBadgeErrorText,
    rfidCardErrorText,
} from 'dg-web-shared/lib/RfidCardValidation.ts';
import { Control } from 'react-hook-form';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { TenantAllowedBarrierGateVehicleIdentification } from 'dg-web-shared/model/TenantEnums.ts';

enum Fields {
    Description = 'description',
    LicensePlate = 'licensePlateNr',
    Country = 'country',
    Type = 'type',
    BadgeLabelNr = 'badgeLabelNr',
    BadgeType = 'badgeType',
}

export type VehicleOnboardingPayload = {
    [Fields.Description]: string;
    [Fields.LicensePlate]: string | null;
    [Fields.Country]: string | null;
    [Fields.Type]: VehicleLicensePlateType | null;
    [Fields.BadgeLabelNr]: string | null;
    [Fields.BadgeType]: ParkingaboUserBadgeType | null;
};

interface VehicleFormProps {
    requestState: ServerRequestState<string, ValidationData>;
    submit: (formData: VehicleOnboardingPayload) => void;
    deleteVehicle?: () => void;
    vehicleDeleteState?: ServerRequestState<string, ValidationData>;
    countriesOptions: ReactHookFormSelectOption<string>[];
    loading?: boolean;
    isOnboarding?: boolean;
    style?: React.CSSProperties;
    readonly?: boolean;
    initialValues?: VehicleOnboardingPayload;
}

function getInitialValues(
    isBadgeRequired: boolean,
    initialValues?: VehicleOnboardingPayload,
) {
    if (initialValues) {
        return {
            [Fields.Description]: initialValues.description,
            [Fields.LicensePlate]: initialValues.licensePlateNr,
            [Fields.Type]: initialValues.type,
            [Fields.Country]: initialValues.country,
            [Fields.BadgeLabelNr]: initialValues.badgeLabelNr,
            [Fields.BadgeType]: initialValues.badgeType,
        };
    }
    return {
        [Fields.Description]: '',
        [Fields.LicensePlate]: null,
        [Fields.Type]: VehicleLicensePlateType.CAR,
        [Fields.Country]: 'CH',
        [Fields.BadgeLabelNr]: null,
        [Fields.BadgeType]: isBadgeRequired
            ? ParkingaboUserBadgeType.ParkingaboBadge
            : null,
    };
}

export function VehicleForm({
    requestState,
    submit,
    deleteVehicle,
    vehicleDeleteState,
    countriesOptions,
    loading,
    isOnboarding,
    style,
    readonly,
    initialValues,
}: VehicleFormProps) {
    const theme = useTheme();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const login = useLogin();
    const { tenant } = useTenant();
    const isBadgeRequired =
        tenant.allowedBarrierGateVehicleIdentification ===
        TenantAllowedBarrierGateVehicleIdentification.BADGE;
    const isLpRequired = isLicensePlateMandatory(
        tenant.allowedEnforcedVehicleIdentification,
        tenant.allowedBarrierGateVehicleIdentification,
    );

    const {
        formInfo: { control, handleSubmit, reset, watch },
        formState: { isDirty },
        genericSubmitError,
    } = useEasyForm(undefined, requestState, language, {
        defaultValues: getInitialValues(isBadgeRequired, initialValues),
    });

    const description = watch(Fields.Description);
    const lp = watch(Fields.LicensePlate);
    const badgeType = watch(Fields.BadgeType);
    const badgeLabel = watch(Fields.BadgeLabelNr);
    const showLpFields = isLpRequired || (!isLpRequired && Boolean(lp));

    useEffect(() => {
        if (initialValues) {
            reset();
        }
    }, [initialValues]);

    function handleEditCancel() {
        reset();
        navigate(-1);
    }

    function handleOnboardingCancel() {
        login.logout();
    }

    return (
        <>
            <FeedbackPopup
                open={deleteConfirmOpen}
                onAbort={() => setDeleteConfirmOpen(false)}
                onConfirm={() => {
                    setDeleteConfirmOpen(false);
                    deleteVehicle && deleteVehicle();
                }}
                abortLabel={
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                }
                confirmLabel={
                    <Localized
                        de="Löschen"
                        fr="Effacer"
                        it="Elimina"
                        en="Delete"
                    />
                }
                title={
                    <Localized
                        de="Fahrzeug löschen"
                        fr="Supprimer véhicule"
                        it="Eliminare veicolo"
                        en="Delete vehicle"
                    />
                }
            >
                <Localized
                    de={`Wollen Sie wirklich das Fahrzeug «${description}» löschen?`}
                    fr={`Voulez-vous vraiment effacer le véhicule «${description}»?`}
                    it={`Vuole veramente eliminare il veicolo «${description}»?`}
                    en={`Do you really want to delete the vehicle «${description}»?`}
                />
            </FeedbackPopup>
            <form
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    ...style,
                }}
                onSubmit={handleSubmit(submit)}
            >
                <Box>
                    <ReactHookFormTextField
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        control={control}
                        name={Fields.Description}
                        required={true}
                        label={
                            <Localized
                                de="Beschreibung"
                                fr="Description"
                                it="Descrizione"
                                en="Description"
                            />
                        }
                        readonly={readonly}
                    />

                    <LicensePlateFields
                        control={control}
                        language={language}
                        readonly={readonly}
                        showLpFields={showLpFields}
                        isLpRequired={isLpRequired}
                        countriesOptions={countriesOptions}
                    />

                    <BadgeFields
                        control={control}
                        language={language}
                        readonly={readonly}
                        badgeType={badgeType}
                        isBadgeRequired={isBadgeRequired}
                        allowedBarrierGateVehicleIdentification={
                            tenant.allowedBarrierGateVehicleIdentification
                        }
                    />

                    <GenericFormSubmitError
                        submitState={requestState}
                        error={genericSubmitError}
                    />
                    {vehicleDeleteState && (
                        <BackendRequestErrorMessage
                            requestState={vehicleDeleteState}
                        />
                    )}
                    {isOnboarding && (
                        <Alert
                            color="info"
                            style={{ marginBottom: theme.spacing(2) }}
                        >
                            <Typography>
                                <Localized
                                    de="Nach der Registrierung können weitere Fahrzeuge direkt im Konto erfasst und verwaltet werden."
                                    fr="Après l'enregistrement, d'autres véhicules peuvent être saisis et gérés directement dans le compte."
                                    it="Dopo la registrazione, è possibile aggiungere e gestire altri veicoli direttamente nel conto."
                                    en="After registration, additional vehicles can be added and managed directly in the account."
                                />
                            </Typography>
                        </Alert>
                    )}
                </Box>
                {!readonly && (isDirty || !deleteVehicle) && (
                    <CancelSave
                        onCancel={
                            !isOnboarding
                                ? handleEditCancel
                                : handleOnboardingCancel
                        }
                        saveDisabled={
                            !isDirty ||
                            description.length === 0 ||
                            (isLpRequired && !lp) ||
                            (isBadgeRequired && !badgeLabel)
                        }
                        requestState={requestState}
                        loading={loading}
                    />
                )}
                {!readonly && !isDirty && deleteVehicle && (
                    <ParkingaboButton
                        loading={
                            vehicleDeleteState?.status === RequestStatus.PENDING
                        }
                        variant={'outlined'}
                        onClick={() => setDeleteConfirmOpen(true)}
                    >
                        <Localized
                            de="Löschen"
                            fr="Supprimer"
                            it="Elimina"
                            en="Delete"
                        />
                    </ParkingaboButton>
                )}
            </form>
        </>
    );
}

function LicensePlateFields({
    control,
    language,
    readonly,
    showLpFields,
    isLpRequired,
    countriesOptions,
}: {
    control: Control<VehicleOnboardingPayload, object>;
    language: Language;
    readonly?: boolean;
    showLpFields: boolean;
    isLpRequired: boolean;
    countriesOptions: ReactHookFormSelectOption<string>[];
}) {
    return (
        <Grid container sx={{ marginBottom: '24px' }}>
            <Grid item xs={5}>
                <ReactHookFormTextField
                    control={control}
                    name={Fields.LicensePlate}
                    required={isLpRequired}
                    label={
                        <Localized
                            de="Kennzeichen"
                            fr="Immatriculation"
                            it="Targa"
                            en="License plate"
                        />
                    }
                    readonly={readonly}
                />
            </Grid>

            {showLpFields && (
                <>
                    <Grid item sx={{ paddingLeft: '10px', flexGrow: 1 }}>
                        <ReactHookFormDropdownSelect
                            control={control}
                            name={Fields.Country}
                            label={
                                <Localized
                                    de="Land"
                                    fr="Pays"
                                    it="Nazione"
                                    en="Country"
                                />
                            }
                            options={countriesOptions}
                            readonly={readonly}
                        />
                    </Grid>
                    <Grid item sx={{ paddingLeft: '10px', flexGrow: 1 }}>
                        <ReactHookFormDropdownSelect
                            control={control}
                            name={Fields.Type}
                            label={
                                <Localized
                                    de="Fahrzeugtyp"
                                    fr="Type de véhicule"
                                    it="Tipo veicolo"
                                    en="Vehicle type"
                                />
                            }
                            options={licensePlateOptions(language)}
                            readonly={readonly}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

function BadgeFields({
    control,
    language,
    readonly,
    badgeType,
    isBadgeRequired,
    allowedBarrierGateVehicleIdentification,
}: {
    control: Control<VehicleOnboardingPayload, object>;
    language: Language;
    readonly?: boolean;
    badgeType: ParkingaboUserBadgeType | null;
    isBadgeRequired: boolean;
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null;
}): JSX.Element | null {
    const typeOptions = badgeTypeOptions(language);
    switch (allowedBarrierGateVehicleIdentification) {
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE:
        case TenantAllowedBarrierGateVehicleIdentification.BADGE:
            return (
                <Grid container sx={{ marginTop: '24px' }}>
                    <Grid item xs={6}>
                        <ReactHookFormDropdownSelect
                            label={
                                <Localized
                                    de="Kartentyp"
                                    fr="Type de carte"
                                    it="Tipo di carta"
                                    en="Card type"
                                />
                            }
                            name={Fields.BadgeType}
                            control={control}
                            options={
                                isBadgeRequired
                                    ? typeOptions
                                    : [
                                          {
                                              display: {
                                                  de: 'Keinen',
                                                  fr: 'Aucune',
                                                  it: 'Nessuna',
                                                  en: 'None',
                                              }[language],
                                              value: '',
                                          },
                                          ...typeOptions,
                                      ]
                            }
                            rules={{
                                required: isBadgeRequired,
                            }}
                            readonly={readonly}
                        />
                    </Grid>
                    {badgeType && (
                        <Grid item xs={6} sx={{ paddingLeft: '10px' }}>
                            <ReactHookFormTextField
                                name={Fields.BadgeLabelNr}
                                control={control}
                                label={
                                    <Localized
                                        de="Kartennummer"
                                        fr="Numéro carte"
                                        it="Numero carta"
                                        en="Card number"
                                    />
                                }
                                rules={{
                                    validate: {
                                        rfidFormat: (value: string) => {
                                            switch (badgeType) {
                                                case ParkingaboUserBadgeType.ParkingpayBadge:
                                                    return (
                                                        rfidCardErrorText(
                                                            value,
                                                            language,
                                                        ) || true
                                                    );
                                                case ParkingaboUserBadgeType.ParkingaboBadge:
                                                    return (
                                                        parkingaboBadgeErrorText(
                                                            value,
                                                            language,
                                                        ) || true
                                                    );
                                            }
                                        },
                                    },
                                }}
                                required={isBadgeRequired}
                                readonly={readonly}
                            />
                        </Grid>
                    )}
                </Grid>
            );

        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR:
        default:
            return null;
    }
}
