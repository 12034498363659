import { Box, Popper, Typography } from '@mui/material';
import { useLanguage } from 'dg-web-shared/common/hooks/LanguageProvider';
import { Language } from 'dg-web-shared/lib/Localized';
import { Theme } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';

export function LanguageSelector() {
    const { language, setLanguage } = useLanguage();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    function handleLanguageSelection(lng: Language) {
        setLanguage(lng);
        setPopoverOpen(false);
    }

    function handleToggle(evt: MouseEvent<HTMLElement>) {
        setAnchorEl(evt.currentTarget);
        setPopoverOpen(o => !o);
    }

    function generateStyle(theme: Theme) {
        return {
            border: 'solid 1px',
            borderBottom: 'solid 0px',
            borderColor: theme.palette.primary.contrastText,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            paddingX: theme.spacing(1),
            paddingY: theme.spacing(0.5),
            textAlign: 'center',
            textTransform: 'uppercase',
            cursor: 'pointer',
            width: 36,
        };
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexBasis: 0,
                justifyContent: 'flex-end',
            }}
        >
            <Typography
                component="button"
                onClick={handleToggle}
                variant="body2"
                sx={theme => ({
                    ...generateStyle(theme),
                    borderBottom: 'solid 1px',
                })}
            >
                {language}
            </Typography>
            <Popper anchorEl={anchorEl} open={popoverOpen} placement="top-end">
                {Object.entries(Language)
                    .filter(([_, value]) => {
                        return value !== language;
                    })
                    .map(([key, value]) => (
                        <Typography
                            component="div"
                            variant="body2"
                            key={value}
                            onClick={() => handleLanguageSelection(value)}
                            sx={generateStyle}
                        >
                            {key}
                        </Typography>
                    ))}
            </Popper>
        </Box>
    );
}
