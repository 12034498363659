import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import { FeedbackPopup } from '../../components/FeedbackPopup.tsx';
import { useNavigate } from 'react-router-dom';

export function QRCodeParsingErrorMessage() {
    const navigate = useNavigate();
    return (
        <FeedbackPopup
            open={true}
            color="error"
            title={
                <Localized
                    de="Ungültiger QR-Code"
                    fr="Code QR invalide"
                    it="Codice QR non valido"
                    en="Invalid QR code"
                />
            }
            abortLabel={'OK'}
            onAbort={() => navigate('/login')}
        >
            <Localized
                de="Der QR-Code enthält keine Parkingabo URL."
                fr="Le code QR ne contient pas une URL Parkingabo."
                it="Il codice QR non contiente un URL Parkingabo."
                en="The QR code does not contain a Parkingabo URL."
            />
        </FeedbackPopup>
    );
}
